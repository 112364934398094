<template>
  <div class="body container">
    <h2 class="text-center mt-5 mb-3">
      {{ $t("bill.parent.inputPayInformation") }}
    </h2>
    <el-row class="rowGap">
      <el-col>
        <OrderItems
          :viewMode="true"
          :displaySelectedCheckbox="false"
          :action="false"
          :orderItems="$store.getters['bill/orderItems']"
          :showTotalPrice="$store.getters['bill/billTotalPrice']"
        />
      </el-col>
    </el-row>
    <el-row class="rowGap">
      <el-col>
        <LessonLogs
          :viewMode="true"
          :displaySelectedCheckbox="false"
          :action="false"
          :billCurrency="$store.getters['bill/billCurrency']"
          :lessonLogs="$store.getters['bill/lessonLogs']"
        />
      </el-col>
    </el-row>
    <h4>{{ $t("bill.parent.payInformation") }}</h4>
    <el-row class="rowGap">
      <el-col>
        <TransactionForm
          v-if="isUnpaid"
          @handleOk="handleOk"
          :displayIsPaidCheckbox="true"
          :submitButtonText="$t('bill.parent.submitPayInformation')"
          :price="$store.getters['bill/billTotalPrice']"
          :currency="$store.getters['bill/billCurrency']"
        />
        <span v-else>
          {{ $t("bill.parent.alreadySubmitedPayInformation") }}
        </span>
      </el-col>
    </el-row>
  </div>
</template>

<script>
import moment from "moment";
import OrderItems from "@/components/bill/OrderItems.vue";
import LessonLogs from "@/components/bill/LessonLogs.vue";
import TransactionForm from "@/components/bill/TransactionForm";
import { EnumBillStatuses } from "@/enums";

export default {
  metaInfo() {
    return {
      title: "Billing Transaction - Ivy Way Academy"
    };
  },
  components: {
    OrderItems,
    LessonLogs,
    TransactionForm
  },
  async created() {
    await this.$store.dispatch("currency/fetchExchangeRate");
    await this.$store.dispatch("bill/getBill", this.$route.params.billId);

    if (this.isPaid) {
      this.$router.push({
        name: "billView",
        params: {
          billId: this.$route.params.billId
        }
      });
    }

    this.$store.dispatch(
      "bill/setLessonLogs",
      this.addPriceByBillCurrency(
        this.$store.getters["bill/lessonLogs"],
        "fee",
        "feeCurrency"
      )
    );
    this.$store.dispatch(
      "bill/setOrderItems",
      this.addPriceByBillCurrency(
        this.$store.getters["bill/orderItems"],
        "price",
        "priceCurrency"
      )
    );
  },
  computed: {
    EnumBillStatuses() {
      return EnumBillStatuses;
    },
    isPaid() {
      return this.$store.getters["bill/billStatus"] === this.EnumBillStatuses.finished;
    },
    isUnpaid() {
      return this.$store.getters["bill/billStatus"] !== this.EnumBillStatuses.finished;
    }
  },
  methods: {
    transformPriceByCurrency(billCurrency, price, fromCurrency) {
      if (price === 0) return 0;
      const exchangeRate = this.$store.getters["currency/exchangeRate"];
      return (
        (price * exchangeRate[fromCurrency].TWD) /
        exchangeRate[billCurrency].TWD
      );
    },
    addPriceByBillCurrency(list, priceColumn, priceCurrencyColumn) {
      return list.map(item => ({
        ...item,
        [`${priceColumn}ByCurrency`]: this.transformPriceByCurrency(
          this.$store.getters["bill/billCurrency"],
          item[priceColumn],
          item[priceCurrencyColumn]
        )
      }));
    },
    async handleOk(transaction) {
      const { price, currency } = transaction;

      const exchangeRate = this.$store.getters["currency/exchangeRate"];
      const changeToBillCurrencyprice =
        (price * exchangeRate[currency].TWD) /
        exchangeRate[this.$store.getters["bill/billCurrency"]].TWD;

      const formattedTransaction = {
        ...transaction,
        price: changeToBillCurrencyprice,
        original_price: price,
        original_currency: currency,
        is_paid: transaction.isPaid,
        datetime: moment(transaction.date).format("YYYY-MM-DD HH:mm:SS")
      };
      await this.$store.dispatch("bill/createTransaction", {
        billId: this.$route.params.billId,
        transaction: formattedTransaction
      });

      this.$message({
        message: this.$t("bill.parent.successSubmitPayInformation"),
        type: "success"
      });

      await this.$store.dispatch("bill/getBill", this.$route.params.billId);
      this.$router.push({
        name: "billView",
        params: {
          billId: this.$route.params.billId
        }
      });
    }
  }
};
</script>

<style scoped>
.rowGap {
  margin: 20px 0px;
}</style
>>

<template>
  <div>
    <h2>{{ $t("bill.form.orderDetails") }}</h2>
    <el-row>
      <el-col :span="24">
        <el-table
          ref="multipleTable"
          :data="lessonLogs"
          style="width: 100%"
          :row-class-name="tableRowClassName"
          :summary-method="getSummaries"
          show-summary
        >
          <el-table-column v-if="displaySelectedCheckbox" width="120">
            <template slot-scope="scope" slot="header">
              <span
                v-if="isSelectedAllLessonLogs"
                key="selectedAll"
                @click="
                  () => {
                    handleSelectedAllLessonLogs(true);
                  }
                "
              >
                <i class="far fa-check-square" />
              </span>
              <span
                v-else
                key="unselectedAll"
                @click="
                  () => {
                    handleSelectedAllLessonLogs(false);
                  }
                "
              >
                <i class="far fa-square" />
              </span>
            </template>
            <template slot-scope="scope">
              <CheckBox
                :selectType="scope.row.selectType"
                @click="
                  selectType => {
                    $emit('switchLessonLogSelectType', {
                      selectType,
                      lessonLogId: scope.row.id,
                      enrolledSessionId: scope.row.enrolledSessionId
                    });
                  }
                "
              />
            </template>
          </el-table-column>
          <el-table-column :label="$t('column.date')">
            <template slot-scope="scope">
              <a
                :href="
                  `/private/private-lessons/${scope.row.classId}/log/${scope.row.id}/student/${scope.row.studentId}`
                "
              >
                {{ instant.formatGoogleTime(scope.row.date) }}
              </a>
            </template>
          </el-table-column>
          <el-table-column :label="$t('column.courses')" property="subject">
            <template slot-scope="scope">
              <a :href="`/private/private-lessons/${scope.row.classId}/detail`">
                {{ scope.row.subject }}
              </a>
            </template>
          </el-table-column>
          <el-table-column
            :label="$t('column.teacher')"
            property="teacherName"
          />
          <el-table-column
            :label="$t('column.student')"
            property="studentName"
          />
          <el-table-column :label="$t('column.hour')" property="hour" />
          <el-table-column :label="$t('column.amount')" width="200">
            <template slot-scope="scope">
              <div class="price">
                <div class="billPrice">
                  {{ scope.row.displayFeeByCurrency }}
                </div>
                <div
                  v-if="scope.row.feeCurrency !== billCurrency"
                  class="originalPrice"
                >
                  ≈{{ scope.row.displayFee }}
                </div>
              </div>
            </template>
          </el-table-column>
          <el-table-column v-if="action" :label="$t('column.action')">
            <template slot-scope="scope">
              <router-link
                :to="
                  `/private/private-lessons/${scope.row.classId}/editLog/${scope.row.id}`
                "
              >
                <i class="fas fa-edit" />
              </router-link>
            </template>
          </el-table-column>
        </el-table>
        <MultipleCurrencyTotalPrompt
          v-if="isCurrencyMultiple(lessonLogsMainCurrencys)"
        />
      </el-col>
    </el-row>
  </div>
</template>

<script>
import CheckBox from "@/components/bill/CheckBox.vue";
import MultipleCurrencyTotalPrompt from "@/components/prompt/MultipleCurrencyTotalPrompt";
import moneyMixin from "@/mixins/money";
import { money, instant } from "@ivy-way/material";
import _ from "lodash";

export default {
  props: {
    lessonLogs: {
      type: Array,
      default: () => []
    },
    displaySelectedCheckbox: {
      type: Boolean,
      default: true
    },
    viewMode: {
      type: Boolean,
      default: false
    },
    action: {
      type: Boolean,
      default: true
    },
    billCurrency: {
      type: String,
      default: null
    }
  },
  watch: {},
  components: {
    CheckBox,
    MultipleCurrencyTotalPrompt
  },
  mixins: [moneyMixin],
  computed: {
    effectiveLessonLogs() {
      if (this.displaySelectedCheckbox) {
        return this.lessonLogs.filter(
          lessonLog => lessonLog.selectType === "selected"
        );
      }
      return this.lessonLogs;
    },
    lessonLogsMainCurrencys() {
      return this.effectiveLessonLogs.map(({ feeCurrency }) => feeCurrency);
    },
    isSelectedAllLessonLogs() {
      return this.lessonLogs.every(
        lessonLog => lessonLog.selectType === "selected"
      );
    },
    instant() {
      return instant;
    }
  },
  methods: {
    handleSelectedAllLessonLogs(selectType) {
      this.lessonLogs.forEach(lessonLog => {
        this.$emit("switchLessonLogSelectType", {
          selectType: selectType ? "selected" : "unSelected",
          lessonLogId: lessonLog.id,
          enrolledSessionId: lessonLog.enrolledSessionId
        });
      });
    },
    getSummaries(param) {
      const { columns, data: lessonLogs } = param;
      const sums = [];
      columns.forEach((column, index) => {
        let columnsText = "";
        if (index === (this.displaySelectedCheckbox ? 4 : 3))
          columnsText = "Total";
        if (index === (this.displaySelectedCheckbox ? 5 : 4))
          columnsText = money.sumUpPrices(
            this.effectiveLessonLogs.map(({ hour }) => hour)
          );
        if (index === (this.displaySelectedCheckbox ? 6 : 5)) {
          const lessonLogsFeeAndCurrency = this.effectiveLessonLogs.map(
            lessonLog => ({
              price: Number(lessonLog.feeByCurrency),
              currency: this.billCurrency
            })
          );
          columnsText = this.addCurrencySymbol(
            this.calculationPriceWithCurrency(lessonLogsFeeAndCurrency),
            this.billCurrency
          );
        }
        sums[index] = columnsText;
      });
      return sums;
    },
    tableRowClassName({ row }) {
      if (row.selectType === "unSelected" && !this.viewMode) {
        return "unSelected";
      }
      return "";
    }
  }
};
</script>

<style lang="scss" scoped>
.price {
  width: 100%;
}

.billPrice {
  font-size: 16px;
}

.originalPrice {
  margin-left: 16px;
  color: #999999;
}

::v-deep .unSelected {
  opacity: 0.5;
}
</style>
